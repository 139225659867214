// import React from "react"
//import ComingSoon from "react-coming-soon"
import React, { Fragment } from "react"
import SlideShow from "../../Elements/SlideShow/index"
import cityMap from "../../../assets/images/map-of-winnipeg-2.jpg"
import bond from "../../../assets/images/bond.png"
import highlandSlideOne from "../../../assets/images/highland-slide-1.png"
import marketingMap from "../../../assets/images/marketing-map.png"
import { Helmet } from 'react-helmet'
import "./style.scss"

const TITLE = 'Ladco Company Limited | Highland Pointe'
const Draft = props => {
  const settings = {
    bgColor: "#fff",
    textColor: "#000",
    height: "100%",
    width: "100%",
  }
  return (
    <Fragment>
      <Helmet>
        <title>{ TITLE }</title>
      </Helmet>
      <img src={bond} style={{ width: "100%", marginBottom: "-10px" }} alt="" />
      <div className="highland-pointe-slideshow-wrapper" style={{backgroundColor:"white"}}>
        <SlideShow settings={settings}>
          <div>
            <img src={highlandSlideOne} style={{ width: "100%" }} alt="" />
          </div>
        </SlideShow>
      </div>
      <div style={{textAlign:"center"}}><h1>The Community of<span style={{color:"#006470"}}> <strong>Highland Pointe</strong> </span>in North West Winnipeg </h1></div>
      <div style={{textAlign:"center"}}>
        <a href={cityMap}>
          <img class="alignnone wp-image-3253 size-large" src={cityMap} alt="" width="550" />
        </a>
      </div>
      <div style={{textAlign:"center", width:"80%", margin:"auto"}}>
        <h1>A variety of single family, duplex and townhome lots will be available Summer of 2021. More information coming soon.</h1>
      </div>
      <div style={{textAlign:"center"}}>
        <a href={marketingMap}>
          <img class="alignnone wp-image-3253 size-large" src={marketingMap} alt="" width="1024" height="682" />
        </a>
      </div>
      {props.children}
    </Fragment>
  )
}

export default Draft
