import React, { Fragment } from "react"
import SlideShow from "../../Elements/SlideShow/index"
import cityMap from "../../../assets/images/map-of-winnipeg-2.jpg"
import bond from "../../../assets/images/bond.png"
import highlandSlideOne from "../../../assets/images/highland-slide-1.png"
import marketingMap from "../../../assets/images/marketing-map.png"
import { Helmet } from 'react-helmet'
import "./style.scss"

const TITLE = 'Ladco Company Limited | Highland Pointe'
const HighlandPointe = props => {
  const settings = {
    bgColor: "#fff",
    textColor: "#000",
    height: "100%",
    width: "100%",
  }
  return (
    <Fragment>
      <Helmet>
        <title>{ TITLE }</title>
      </Helmet>
      <img src={bond} style={{ width: "100%", marginBottom: "-10px" }} alt="" />
      <div className="highland-pointe-slideshow-wrapper" style={{backgroundColor:"white"}}>
        <SlideShow settings={settings}>
          <div>
            <img src={highlandSlideOne} style={{ width: "100%" }} alt="" />
          </div>
        </SlideShow>
      </div>
      {props.children}
    </Fragment>
  )
}

export default HighlandPointe
